.dynamicFormElements input[type='number']::-webkit-inner-spin-button,
.dynamicFormElements input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dynamicFormElements input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.dynamicFormElements .custom-slider::-webkit-slider-thumb {
  width: 14px;
  height: 14px;
  background: #79bc61 !important;
  cursor: pointer;
  -webkit-appearance: none; /* Note the browser prefix */
}

.custom-slider::-webkit-slider-runnable-track {
  height: 4px;
  background: #d3d6d2;
  border-radius: 5px;

  top: 50%;
  /* Add more styles */
}

/* Firefox */
.custom-slider::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background: #79bc61;
  cursor: pointer;
  /* Add more styles */
}

.custom-slider::-moz-range-track {
  height: 4px;
  background: #d3d6d2;
  /* Add more styles */
}
