
html {
  margin: 0;
  padding: 0;

}
body {
  margin: 0;
  padding: 0;
  touch-action: manipulation !important;

}

* {
  box-sizing: border-box;
  /*color: var(--color-black);*/
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  list-style: none;
  /*touch-action: pan-x pan-y !important;*/
}

body:not(.touched) *:not(canvas) {
  touch-action: pan-y !important;


}

body.touched *:not(canvas) {
  touch-action: manipulation !important;
  /*touch-action: none !important;*/
  /*overflow-x: hidden !important;
  overflow-y: hidden !important;*/
}


canvas {
  outline: none;
  width: 100% ;
  height: 100% ;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  color:#333336
}


body.nesw-resize {
  cursor: nesw-resize
}

body.nwse-resize {
  cursor: nwse-resize
}

body.move {
  cursor: move
}

body.rotate {
  cursor: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"%3E%3Cdefs%3E%3Cfilter id="a" width="266.7%25" height="156.2%25" x="-75%25" y="-21.9%25" filterUnits="objectBoundingBox"%3E%3CfeOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/%3E%3CfeGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1"/%3E%3CfeColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/%3E%3CfeMerge%3E%3CfeMergeNode in="shadowMatrixOuter1"/%3E%3CfeMergeNode in="SourceGraphic"/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id="b" d="M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z"/%3E%3C/defs%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath d="M0 24V0h24v24z"/%3E%3Cg fill-rule="nonzero" filter="url(%23a)" transform="rotate(90 5.25 14.75)"%3E%3Cuse fill="%23000" fill-rule="evenodd" xlink:href="%23b"/%3E%3Cpath stroke="%23FFF" d="M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")" transform="rotate(90 5.25 14.75)"%3E%3Cuse fill="%23000" fill-rule="evenodd" xlink:href="%23b"/%3E%3Cpath stroke="%23FFF" d="M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E') 12 12, auto;
  cursor: -webkit-image-set(
      url("../public/assets/img/mouse/rotate.png") 1x,
      url("../public/assets/img/mouse/rotate2X.png") 2x) 12 12, move;
}



